import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { geturlPrefix } from "../../utils/functions";
import { fetchCategories, fetchContents } from "../../utils/api";
import { setCategories, setContents } from "../../store/reducers/commonReducer";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";

import {Right} from '../page/htmlelements/arrows/Right';
import {Left} from '../page/htmlelements/arrows/Left';

let settings = {
    infinite: false,
    // autoplay: true,
    // pauseOnHover: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <Right/>,
    prevArrow: <Left/>,
    // dots: true,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: false,

            }
        },
        {
            breakpoint: 1240,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
            }
        }
    ]
};

export const Cards = () => {
    let tabindex = 0;
    const hasMounted = React.useRef(false);
    const dispatch = useDispatch();
    const homeData = useSelector((state: RootState) => state.common.homeData);
    const categories = useSelector((state: RootState) => state.common.categories);
    const contents = useSelector((state: RootState) => state.common.contents);
    const accountInfo = useSelector((state: RootState) => state.common.accountInfo);
    const title = homeData?.title ? homeData?.title : "";
    const subTitle = homeData?.description ? homeData?.description : "";
    let urlPrefix = geturlPrefix();
    const loadCategoriesAndContents = () => {
        if (categories.length === 0) {
            loadCategories();
        }
        if (contents.length === 0) {
            loadContents();
        }

    }
    const loadCategories = async () => {
        const result = await fetchCategories(accountInfo);
        if (result) {
            dispatch(setCategories(result));
        }
    }
    const loadContents = async () => {
        const result = await fetchContents(accountInfo);
        if (result?.data) {
            dispatch(setContents(result.data));
        }
    }
    useEffect(() => {
        if (hasMounted.current) return;

        hasMounted.current = true;
        loadCategoriesAndContents();
    }, []);
    const navigate = useNavigate();
    return (
        <div className="ms-ai-card-list-wrapper">
        <div className="ms-ai-card-list-container ai-container">
            <div className="ms-ai-card-cat-block">
                <div className="ms-ai-inner-page-main-title">
                    {title}
                </div>
                <div className="ms-ai-inner-page-main-desc mw-100">
                    {subTitle}
                </div>

                {categories &&  categories.length > 0 &&  categories.map((data: any, index: number) => {
                    const subItems = contents.filter((x: any) => x.category_id === data._id);
                    if (!data.hide_from_page) {
                        // const settingsnew: any = formatSettingsinfinity(subItems.length);
                        return (
                            <div className='ms-ai-card-cat-block ms-ai-slider-container'
                                 key={"main-category-" + index}>
                                <div className="ms-ai-card-cat-title">{data.title}</div>
                                <div className="ms-ai-card-block">
                                    <div
                                        className="slider-container slide-paddings home-slider-cards">
                                        <Slider {...settings}>
                                            {subItems.map((subItem: any, index: number) => {
                                                //tabindex++;
                                                return (
                                                    <Link aria-label={data.title}
                                                          to={subItem.comingsoon ? '#' : "/" + subItem.url_path}
                                                          key={"ms-ai-card-item-" + index}
                                                          className={`${subItem.comingsoon ? 'coming-soon-item' : ''}`}>
                                                        <div tabIndex={tabindex}
                                                             onKeyDown={(event: any) => {
                                                                 if (event?.code === "Space" || event.key === "Enter") {
                                                                     navigate("/" + subItem.key)
                                                                 }
                                                             }
                                                             } className="ms-ai-card-item"
                                                        >
                                                            <div
                                                                className="ms-ai-card-bg-img">
                                                                <img
                                                                    src={urlPrefix + (subItem.image_url ? subItem.image_url : 'assets/images/usecases/transparent.png')}
                                                                    alt=""/>
                                                            </div>
                                                            <div
                                                                className="ms-ai-card-name">
                                                                <span>{subItem.title}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}


            </div>

        </div>
    </div>
    )
}