import Slider from "react-slick";
import {useNavigate} from "react-router-dom";
import { geturlPrefix } from "@src/utils/functions";

interface IProps {
    data: any
    tabindex: number
}

let settings = {
    infinite: false,
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    // adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 1240,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
            }
        },

    ]
};

export const ImageCard = (props: IProps) => {
    let {data, tabindex} = props;
    const navigate = useNavigate();
    const checkPageData = (itemkey: string) => {
        navigate("/" + itemkey);
    }
    // const formatSettingsinfinity = (itemsLength: number) => {
    //     let settingsnew = settings;

    //     settingsnew.infinite = itemsLength > settingsnew.slidesToShow;
    //     let responsiveData = settingsnew.responsive.map((item: any) => {
    //         item.settings.infinite = itemsLength > item.settings.slidesToShow;
    //         return item;
    //     });
    //     settingsnew.responsive = responsiveData;
    //     return settingsnew;
    // }
    const items = data.items ? data.items : [];
    // const settingsnew: any = formatSettingsinfinity(items.length);
    let urlPrefix = geturlPrefix();

    return (
        <div className="ms-ai-card-cat-block">
            <div className="ms-ai-inner-page-sub-headeing">
                {data.title}
            </div>
            <div className="ms-ai-card-block">
                <div className="slider-container slide-paddings">
                    <Slider {...settings}>
                        {items.map((itemdata: any, itemindex: number) => {
                            const item = itemdata.content_data ? itemdata.content_data : itemdata;
                            let imageUrl =  item?.image_url ? item?.image_url  : 'assets/images/usecases/img.png';
                            if(imageUrl && !imageUrl.startsWith("http")) {
                                imageUrl = urlPrefix + imageUrl;
                            }
                            return (
                                <div key={"image-card-item-" + itemindex}
                                     onClick={() => item?.url_path && checkPageData(item?.url_path)}>
                                    <div className="ms-ai-card-item">
                                        <div className="ms-ai-card-bg-img">
                                            {imageUrl && <img src={imageUrl} alt=""/>}
                                        </div>
                                        <div className="ms-ai-card-name">{item.content_data?.title ? item.content_data?.title : item.title}</div>
                                    </div>

                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    )
}