interface IProps {
    data: any
    tabindex: number
}

export const TextTag = (props: IProps) => {
    let {data, tabindex} = props;
    const items = data.items ? data.items : [];
    return (
        <>
            {data.title && <div className="innerpage-sub-title-light">
                {data.title}
            </div>}
            <div className="ms-ai-circle-graph-wrapper">
                {items.map((item: any, itemindex: number) => {
                    //tabindex++;
                    let bgcolor = "#555";
    
                    if (item.statusclass === "bg-green") {
                        bgcolor = "#7EB900";
                    } else if (item.statusclass === "bg-orange") {
                        bgcolor = "#F2B209";
                    } else if (item.statusclass === "bg-orange") {
                        bgcolor = "#F14F21";
                    }
                    if (item.bgcolor) {
                        bgcolor = item.bgcolor;
                    }
                    let itemTitle = item.content_data?.title ? item.content_data?.title : item.title;
                    return (
                        <div tabIndex={tabindex} key={"graph-item-" + itemindex} className="ms-ai-circle-graph-item">
                            {itemTitle && <div className="ms-ai-circle-graph-title">
                                {itemTitle}
                            </div>}
                            {item.status &&
                                <div className={`ms-ai-circle-graph-status` } style={{ backgroundColor: bgcolor }}>{item.status}</div>}
                            {/* <div className="ms-ai-circle-graph-svg">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13.5998" cy="13.5998" r="13.2472"
                                        transform="rotate(-90 13.5998 13.5998)" stroke="#59A7E9"
                                        strokeWidth="0.705175"/>
                                <path
                                    d="M13.5998 -2.46334e-05C11.8139 -2.46547e-05 10.0454 0.351745 8.39539 1.0352C6.74538 1.71865 5.24615 2.72041 3.98329 3.98327C2.72043 5.24612 1.71868 6.74536 1.03522 8.39536C0.351769 10.0454 1.05407e-07 11.8138 1.62176e-07 13.5998L13.5998 13.5998L13.5998 -2.46334e-05Z"
                                    fill="#59A7E9"/>
                            </svg>
                        </div> */}
                        </div>
                    )
                })}
            </div>
        </>
    )
}