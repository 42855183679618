import AudioPlayer from "@src/components/audioplayer"
import { setPodcastInfo } from "@src/store/reducers/commonReducer"
import { useDispatch, useSelector } from "react-redux"
import podcastAudio from "@src/assets/podcast/Manufacturing Podcast_v3.mp3";
import podcastAudioAFA from "@src/assets/podcast/AI Factory Assistant Podcast_v3.mp3";
import headset from "@src/assets/images/podcast/headset.svg";
import { RootState } from "@src/store/store";

interface IProps {
    data: any
    pageDetails: any
    index: number
    pageTitleItem: boolean
}


export const TitleDescription = (props: IProps) => {
    const {data, pageDetails, index, pageTitleItem} = props;
    const dispatch = useDispatch();
    const podcastInfo = useSelector((state: RootState) => state.common.podcastInfo);
    const podcastPage = podcastInfo? podcastInfo.title : '';
    const filePath = podcastInfo? podcastInfo.url : '';

    const getFormattedDate = (): string => {
        const today = new Date();
        return today.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        });
      };
    const contentTitle = () => {
        let title = data?.title;
        if(pageTitleItem && pageDetails?.title) {
            title = pageDetails?.title;
        }
        
        return title;
    }
    const playAudio = (title: string) => {
        if(title.toLowerCase() === 'manufacturing') {
            const audioData = {
                date: getFormattedDate(),
                title: title,
                subtitle: 'Industry Vision',
                url: podcastAudio
            }
            dispatch(setPodcastInfo(audioData));
        } else if(title.toLowerCase() === 'ai factory assistant') {
            const audioData = {
                date: getFormattedDate(),
                title: title,
                subtitle: 'Industry Vision',
                url: podcastAudioAFA
            }
            dispatch(setPodcastInfo(audioData));
        }
    }
    const title = contentTitle();
    return (
        <div>
            {title && <div className="ms-ai-inner-page-title-btn-wrapper"><div className={`${data.classname ? data.classname : "ms-ai-inner-page-main-title"}`}
                                dangerouslySetInnerHTML={{__html: title}}></div>{(title.toLowerCase() === 'manufacturing' || title.toLowerCase() === 'ai factory assistant') ? <button onClick={() => playAudio(title)} className="ms-ai-listen-btn"><img src={headset} width={13} />{`  Listen to Podcast  ${title.toLowerCase() === 'manufacturing' ? '4m' : '5m'}`}</button> : ''}</div>}
                                
            {data.items.map((itm: { subtitle?: string, description?: string }, ind: number) => {
                return (
                    <>
                        <div className="ms-ai-inner-page-main-desc">
                            <p className="innerpage-sub-title">{itm.subtitle && <div dangerouslySetInnerHTML={{__html: itm.subtitle}}/> }</p>
                            <p>
                                {itm.description && <div dangerouslySetInnerHTML={{__html: itm.description}}/>}
                               
                            </p>
                            {(index === 0 && ind === 0 && title  === podcastPage && filePath) && <AudioPlayer />}
                            </div>
                            
                    </>
                )
            })}
            {(data.subtitle || data.description) && <div className="ms-ai-inner-page-main-desc">
                {data.subtitle && <p className="innerpage-sub-title">{data.subtitle && <div dangerouslySetInnerHTML={{__html: data.subtitle}}/>}</p>}
                {data.description && <p>
                    <div dangerouslySetInnerHTML={{__html: data.description}}/>
                </p>}
            </div>}
        </div>
    )
}