// features/counter/counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface HomeDataType {
  title: string;
  description: string
}
interface MenuItemType {
  _id(_id: any): unknown;
  name: string,
  parent_id? : string,
  content_id?: string,
  technical_pattern: boolean
}
interface Podcastinfo {
  date: string,
  title: string,
  subtitle: string,
  url: any
}
interface CounterState {
  page: string,
  pageData: any,
  homeData: null|HomeDataType,
  sidebar: boolean,
  categories: any,
  contents: any,
  menu_items: MenuItemType[],
  path: any,
  accountInfo: null|accountInfoType,
  podcastInfo: null|Podcastinfo
}

interface accountInfoType {
  idtoken?:  string
  tenantid?: string
}

const initialState: CounterState = {
  page: '',
  pageData: '',
  homeData: null,
  sidebar: false,
  categories: [],
  contents: [],
  menu_items: [],
  path: [],
  accountInfo: null,
  podcastInfo: null,
};

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<string>) => {
        state.page = action.payload;
    },
    setPageData: (state, action: PayloadAction<any>) => {
        state.pageData = action.payload;
    },
    setHomeData: (state, action: PayloadAction<null|HomeDataType>) => {
      state.homeData = action.payload;
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebar = action.payload;
    },
    setCategories: (state, action: PayloadAction<any>) => {
      state.categories = action.payload;
    },
    setContents: (state, action: PayloadAction<any>) => {
      state.contents = action.payload;
    },
    setMenuItems: (state, action: PayloadAction<MenuItemType[]>) => {
      state.menu_items = action.payload;
    },
    setPath: (state, action: PayloadAction<any[]>) => {
      state.path = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<accountInfoType|null>) => {
      state.accountInfo = action.payload;
    },
    setPodcastInfo: (state, action: PayloadAction<null|Podcastinfo>) => {
      state.podcastInfo = action.payload;
    },
  },
});

export const { setPage, setPageData, setHomeData, setSidebar, setCategories, setContents, setMenuItems, setPath, setAccountInfo, setPodcastInfo } = counterSlice.actions;
export default counterSlice.reducer;
