interface IProps {
    data: any
    tabindex: number
}

export const IconText = (props: IProps) => {
    let {data, tabindex} = props;
    const items = data.items ? data.items : [];
    return (
        <div>
            <div className={`${data.classname ? data.classname : "innerpage-sub-title-light"}`}>
                {data.title}
            </div>
            <div className="ms-ai-services-wrapper">
                {items.map((item: any, index: number) => {
                    //tabindex++;
                    return (
                        <div tabIndex={tabindex} className="ms-ai-services-block" key={"icon-text-" + index}>
                            <div className="ms-ai-services-item">
                                <div className="ms-ai-services-logo">
                                    {item.icon === 'globe' && <svg
                                        width={24}
                                        height={26}
                                        viewBox="0 0 24 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_116_734)">
                                            <g clipPath="url(#clip1_116_734)">
                                                <g clipPath="url(#clip2_116_734)">
                                                    <path
                                                        d="M11.8403 22.8605C17.3079 22.8605 21.7403 18.4282 21.7403 12.9605C21.7403 7.49293 17.3079 3.06055 11.8403 3.06055C6.37269 3.06055 1.94031 7.49293 1.94031 12.9605C1.94031 18.4282 6.37269 22.8605 11.8403 22.8605Z"
                                                        stroke="#0078D4"
                                                        strokeWidth="1.98"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M1.94019 12.96H21.7402"
                                                        stroke="#0078D4"
                                                        strokeWidth="1.98"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M11.8404 3.06055C14.3166 5.77152 15.7239 9.28966 15.8004 12.9605C15.7239 16.6314 14.3166 20.1496 11.8404 22.8605C9.3641 20.1496 7.95685 16.6314 7.88037 12.9605C7.95685 9.28966 9.3641 5.77152 11.8404 3.06055Z"
                                                        stroke="#0078D4"
                                                        strokeWidth="1.98"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_116_734">
                                                <rect
                                                    width="22.488"
                                                    height="25.6"
                                                    fill="white"
                                                    transform="translate(0.760254 0.280273)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip1_116_734">
                                                <rect
                                                    width="22.488"
                                                    height="25.6"
                                                    fill="white"
                                                    transform="translate(0.760254 0.280273)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip2_116_734">
                                                <rect
                                                    width="22.488"
                                                    height="25.5898"
                                                    fill="white"
                                                    transform="translate(0.760254 0.285156)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    {item.icon === 'headset' && <svg
                                        width={23}
                                        height={25}
                                        viewBox="0 0 23 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_116_719)">
                                            <path
                                                d="M2.17725 19.6151V13.1999C2.17725 10.6478 3.19107 8.20023 4.9957 6.3956C6.80032 4.59098 9.24792 3.57715 11.8 3.57715C14.3522 3.57715 16.7998 4.59098 18.6044 6.3956C20.409 8.20023 21.4228 10.6478 21.4228 13.1999V19.6151"
                                                stroke="#0078D4"
                                                strokeWidth="2.1384"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21.4228 20.6839C21.4228 21.251 21.1976 21.7949 20.7965 22.196C20.3955 22.597 19.8516 22.8223 19.2844 22.8223H18.2152C17.6481 22.8223 17.1042 22.597 16.7032 22.196C16.3021 21.7949 16.0768 21.251 16.0768 20.6839V17.4763C16.0768 16.9092 16.3021 16.3652 16.7032 15.9642C17.1042 15.5632 17.6481 15.3379 18.2152 15.3379H21.4228V20.6839ZM2.17725 20.6839C2.17725 21.251 2.40254 21.7949 2.80357 22.196C3.2046 22.597 3.74851 22.8223 4.31565 22.8223H5.38485C5.95199 22.8223 6.4959 22.597 6.89692 22.196C7.29795 21.7949 7.52325 21.251 7.52325 20.6839V17.4763C7.52325 16.9092 7.29795 16.3652 6.89692 15.9642C6.4959 15.5632 5.95199 15.3379 5.38485 15.3379H2.17725V20.6839Z"
                                                stroke="#0078D4"
                                                strokeWidth="2.1384"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_116_719">
                                                <rect width={23} height={25} fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                    {item.icon === "refresh" && <svg
                                        width={23}
                                        height={26}
                                        viewBox="0 0 23 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_116_743)">
                                            <g clipPath="url(#clip1_116_743)">
                                                <g clipPath="url(#clip2_116_743)">
                                                    <g clipPath="url(#clip3_116_743)">
                                                        <g clipPath="url(#clip4_116_743)">
                                                            <path
                                                                d="M1.05994 5.48047V10.8805H6.45994"
                                                                stroke="#0078D4"
                                                                strokeWidth="1.8"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M20.86 19.8805V14.4805H15.46"
                                                                stroke="#0078D4"
                                                                strokeWidth="1.8"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M18.6009 9.9799C18.1445 8.69001 17.3687 7.53676 16.346 6.62778C15.3233 5.71879 14.087 5.08369 12.7525 4.78173C11.4179 4.47978 10.0287 4.52081 8.71426 4.901C7.39987 5.28119 6.20321 5.98814 5.23594 6.9559L1.05994 10.8799M20.8599 14.4799L16.6839 18.4039C15.7167 19.3717 14.52 20.0786 13.2056 20.4588C11.8912 20.839 10.5019 20.88 9.16739 20.5781C7.83286 20.2761 6.59656 19.641 5.57386 18.732C4.55116 17.823 3.77539 16.6698 3.31894 15.3799"
                                                                stroke="#0078D4"
                                                                strokeWidth="1.8"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_116_743">
                                                <rect
                                                    width="22.488"
                                                    height="25.6"
                                                    fill="white"
                                                    transform="translate(0.159912 0.280273)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip1_116_743">
                                                <rect
                                                    width="22.488"
                                                    height="25.6"
                                                    fill="white"
                                                    transform="translate(0.159912 0.280273)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip2_116_743">
                                                <rect
                                                    width="22.488"
                                                    height="25.5898"
                                                    fill="white"
                                                    transform="translate(0.159912 0.285156)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip3_116_743">
                                                <rect
                                                    width="23.76"
                                                    height="23.76"
                                                    fill="white"
                                                    transform="translate(-0.640137 1.08008)"
                                                />
                                            </clipPath>
                                            <clipPath id="clip4_116_743">
                                                <rect
                                                    width="21.6"
                                                    height="21.6"
                                                    fill="white"
                                                    transform="translate(0.159912 1.87988)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>}
                                </div>
                                <div className="ms-ai-services-title">{item.content_data?.title ? item.content_data?.title : item.title}</div>
                            </div>
                            {/* <div className="ms-ai-services-progressbar">
                                <span className="ms-ai-bg-green ms-bg-green"/>
                            </div> */}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}