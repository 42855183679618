import Slider from "react-slick";
import {useNavigate} from "react-router-dom";
import React from "react";
import {Right} from "../arrows/Right";
import {Left} from "../arrows/Left";
import { geturlPrefix } from "@src/utils/functions";
import { useSelector } from "react-redux";
import { RootState } from "@src/store/store";

interface IProps {
    data: any;
    key: string
    tabindex: number
}

let settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <Right/>,
    prevArrow: <Left/>,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
            }
        },

    ]
};

export const CardSlider = (props: IProps) => {
    const {data, key} = props;
    let {tabindex} = props;
    const navigate = useNavigate();
    const contents = useSelector((state: RootState) => state.common.contents);
    let sliderRef = React.useRef<any>(null);
    const checkPageData = (slide: any) => {
        if (slide.link && slide.link !== "#") {
            window.open(slide.link, '_blank', 'rel=noopener noreferrer')
        } else if (slide.content_id) {
            const contentItem = contents.find((x:any) => x._id === slide.content_id)
            if(contentItem) {
                navigate("/" + contentItem.url_path);
            }
            
        }

    }
    // const formatSettingsinfinity = (itemsLength: number) => {
    //     let settingsnew = settings;

    //     // settingsnew.infinite = itemsLength > settingsnew.slidesToShow;
    //     let responsiveData = settingsnew.responsive.map((item: any) => {
    //         item.settings.infinite = itemsLength > item.settings.slidesToShow;
    //         return item;
    //     });
    //     settingsnew.responsive = responsiveData;
    //     return settingsnew;
    // }
    const items = data.items ? data.items : [];
    // const settingsnew: any = formatSettingsinfinity(items.length);
    let urlPrefix = geturlPrefix();
    
    // React.useEffect(() => {
    //     if(sliderRef.current)
    //         sliderRef?.current?.slickGoTo(0);
    // },[data])
    return (
        <div className={`ms-ai-slider-wrapper ${data.classname ? data.classname : ""} `}>
            {items.length > 0 && (<div className="ms-ai-slider-container">
                <div className="innerpage-sub-title">{data.title}</div>
                <div className="ms-ai-slider-block">
                    <div className="slider-container slide-paddings">
                        <Slider key={'slider-' + key} {...settings} ref={sliderRef}>
                            {items.map((slide: any, slideindex: number) => {
                                let imageUrl = urlPrefix + 'assets/images/usecases/img.png';

                                if (slide.thumbnail) {
                                    if (slide.thumbnail.startsWith("http")) {
                                        imageUrl = slide.thumbnail;
                                    } else {
                                        imageUrl = urlPrefix + slide.thumbnail;
                                    }

                                }
                              
                                return (
                                    <div key={"slide-card-" + slideindex} className={`ms-ai-slider-item ${slide.classname ? slide.classname : ''}`}
                                         onClick={() => slide && checkPageData(slide)}>

                                        <div className="ms-ai-slider-img-block">
                                            <img src={imageUrl} alt=""/>
                                        </div>
                                        <div className="ms-ai-slider-title">
                                            {slide.content_data?.title ? slide.content_data?.title : slide.title}
                                        </div>
                                        <div className="ms-ai-slider-desc">
                                            <div dangerouslySetInnerHTML={{__html: slide.description}}/>
                                        </div>
                                        {(slide.link || slide.key) && <a className="ms-ai-learnmore" href={slide.link}
                                                          target={slide.link === "#" ? "_self" : "_blank"}>
                                    <span>
                                        <svg
                                            width={26}
                                            height={27}
                                            viewBox="0 0 26 27"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.459839"
                                                y="0.789062"
                                                width="25.0611"
                                                height="25.2778"
                                                rx="5.77778"
                                                fill="#0078D4"
                                            />
                                            <g clipPath="url(#clip0_126_3085)">
                                                <path
                                                    d="M10.5709 17.1517C10.5709 17.06 10.6044 16.9807 10.6714 16.9137L14.1521 13.4277L10.6714 9.94181C10.6044 9.87481 10.5709 9.79546 10.5709 9.70378C10.5709 9.61209 10.6044 9.53274 10.6714 9.46574C10.7384 9.39874 10.8178 9.36523 10.9095 9.36523C11.0012 9.36523 11.0805 9.39874 11.1475 9.46574L14.8715 13.1897C14.9385 13.2567 14.972 13.336 14.972 13.4277C14.972 13.5194 14.9385 13.5988 14.8715 13.6658L11.1475 17.3897C11.0805 17.4567 11.0012 17.4902 10.9095 17.4902C10.8178 17.4902 10.7384 17.4567 10.6714 17.3897C10.6044 17.3227 10.5709 17.2434 10.5709 17.1517Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_126_3085">
                                                    <rect
                                                        width="10.6167"
                                                        height="10.8333"
                                                        fill="white"
                                                        transform="matrix(1 0 0 -1 10.5709 18.8447)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                            <span>Learn more</span>
                                        </a>}
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div>)}
        </div>
    )
}